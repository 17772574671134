.root {
    height: 100%;
    width: 100%;
}
.help_stage[data-visible='0'] {
    display: none;
}
.help_stage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(80,80,80);
    min-height: 100vh;
    width: 100%;
}
.help_stage h2 {
    font-size: 40px;
    max-width: 700px;
    text-align: center;
}
.help_stage .help_header_highlight {
    color: #4c32b6;
    display: inline-block;
}
.help_stage p {
    color: rgb(120,120,120);
    font-size: 16px;
}
.help_commands {
    margin-top: 50px;
}
.help_commands button {
    font-size: 20px;

    background-color: #4c32b6;
    color: rgb(250,250,250);
    border: 0;
    padding: 15px 30px;
    border-radius: 6px;
    font-weight: 550;
    
    cursor: pointer;
    box-shadow: 0 0 3px rgba(0,0,0,0.4);

    transition: box-shadow 350ms ease, color 350ms ease, opacity 350ms ease;

    display: flex;
    align-items: center;
}
.help_commands button:hover {
    color: white;
    box-shadow: 0 0 10px rgba(0,0,0,0.6);
}
.help_commands button[disabled] {
    opacity: 0.2;
}
.help_stage p.help_small {
    color: rgb(180,180,180);
}

.progress_container {
    margin-top: 10px;
}

@media (max-width: 736px) {
    .root {
        padding: 20px;
        width: calc(100% - 40px);
        text-align: center;
    }
    .root ol {
        text-align: left;
    }
    .help_stage h2 {
        font-size: 37px;
    }
}