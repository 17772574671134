.App {
    text-align: center;
    font-weight: bold;
  }


.hobby {
    position: absolute;
    top: -10000px;
    left: -10000px;
}