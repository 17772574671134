.root h3 {
    font-size: 18px;
    padding-bottom: 20px;
}
@media (max-width: 736px) {
    
    .sections {
        padding: 0px 20px;
    }
}

.sections {
    max-width:600px;
    margin: auto;
    margin-top: 40px;
}
.section:not(:first-child) {
    margin-top:50px;
}

.card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    padding: 12px;
    padding-left: 5px;
    border-radius: 4px;
    margin: 2px 2px 8px;
    transition: opacity .2s;
    border: 1px solid #f0f0f0;
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
    background-color: #fff;
}
.card .margin {
    width: 16px;
    padding-right:7px;
}
.card .margin :global(.material-icons) {
    font-size:16px;
    color: rgb(51, 51, 51);
}
.card .body {

  display: flex;
  flex-direction: column;
  font-size: 14px;
  
  flex-grow: 1;
  
}

.section h3 {
    display: flex;
    align-items: center;
}
.section h3 .section_header_commands {
    margin-left: auto;
    font-size: 14px;
    color: rgb(120,120,120);
}
.section h3 :global(.material-icons) {
    margin-right: 5px;
}
.section_empty_message {
    border: 1px dashed rgb(223,225,230);
    border-radius: 4px;
    font-size: 14px;
    color: #292d34;
    padding: 20px 12px;
    text-align:center;

}
.card .body .header {
    display: flex;
    align-items: center;

}
.card .body .header .title {
    color: rgb(51, 51, 51);
    flex-grow: 1;
}
.reminder_time {
    display: flex;
    align-items: center;
}
.email_date {
  margin-right: 20px;
  color: rgb(120,120,120);
}
.reminder_time :global(.material-icons) {
    font-size: 16px;
    margin-right:5px;
}
.success {
    margin-top:30px;
  color: rgb(80,80,80);
  line-height: 22px;
} 
.reminder_commands {
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
    
    align-items: center;
}
.reminder_commands .command {
    text-decoration: underline;
}
.reminder_commands .command:not(:first-child) {
    margin-left:10px;
}
.command {
    border-radius: 6px;
    padding: 2px 4px;
}
.command:hover {
    background-color: rgb(250,250,250);
    cursor: pointer;
    
}
.hero_pane {
  display: flex;
  flex-direction: column;
  width:calc(100% - 100px);
  padding: 35px 50px;
  background-color:rgb(99,133,242);
  color:white;
  
  
  min-height: 40px;
  
  font-weight: bold;
}
.hero_pane .brand {
    flex-grow: 1;
    font-size: 18px;
}
.hero_pane .title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.hero_pane .title h2 {
    font-size: 30px;
    text-align: center;
}
.hero_pane .backRow {
    display: flex;
    align-items: center;
}
.hero_pane .back {
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
}
.hero_pane .back :global(.material-icons) { 
  font-size: 14px;
}
.backEsc {
    margin-left:8px;
    opacity:0.7;
}
@media (max-width: 736px) {
    .hero_pane {
        width:calc(100% - 60px);
        padding: 35px 30px;
    }
    .hero_pane .backRow {
        display: none;
    }
    .hero_pane .title h2 {
        font-size: 25px;
    }
}


.people {
    width: 100%;
    display:flex;
    justify-content:center;
}
.person {
    
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}
.person .personImage {
    margin-top:-40px;
    height:70px;
    width:70px;
    padding: 10px;
    font-size:80px;
    border-radius:45px;
    background-color:white;
}
.person .personName {
    color: rgb(120,120,120);
    font-size: 14px;
}

.bylineContainer {
    width:100%;
    display:flex;
    justify-content:center;
    margin-top:20px;
    font-size: 18px;
}



.remindTimePicker {
    margin: 30px 10px;
    border: 1px solid rgb(223,225,230);
    border-radius: 6px;
    padding-bottom: 30px;
}
.remindTimePickerLine {
    padding: 10px 30px;
    display: flex;
    align-items: center;
}
.remindTimePickerLineGutter {
    margin-left: -20px;
    width: 20px;
}
.remindTimePickerLineGutter:global(.material-icons) {
    font-size: 14px;
}
.remindTimePickerTitle {
    padding-bottom: 0px;
}
.remindTimePickerByline {
    color: rgb(120,120,120);
    margin-bottom: 20px;
    line-height: 22px;
}
.remindTimePickerOtherContainer {
    display: flex;
    align-items: center;
}
.remindTimePickerOtherTitle {
    min-width: 120px;
}
.remindTimePickerLine:not(:hover) .remindTimePickerLineGutterOnlyHover  {
    display:none;
}
.remindTimePickerLineClickable {
    cursor: pointer;
    background-color: inherit;
}
.remindTimePickerLineClickable:hover {
    background-color: rgb(249,249,249);
}
.remindTimePickerGoBack {
    color: rgb(120,120,120);
    display: flex;
    align-items: center;
    
    margin-top: 30px;
    
}
@media (max-width: 736px) {
    .remindTimePicker {
        margin: 30px 0px;
    }
    .remindTimePickerOtherContainer {
        flex-direction: column;
        align-items: flex-start;
    }
    .remindTimePickerOtherTitle {
        margin-bottom:5px;
    }
}

.noReminder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 40px;

}

.help {
    

    padding: 12px 25px;
    border-radius: 4px;
    margin: 2px 2px 8px;
    transition: opacity .2s;
    border: 1px solid #f0f0f0;
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
    background-color: #fff;

    color: rgb(51, 51, 51);
    font-size: 14px;
}
.help h4 {
    margin-top: 40px;
}

.section_divider {
    width: 50%;
    margin: auto;
    margin-top: 55px;
    border-top: 1px solid rgb(210,210,210);
    margin-bottom: 55px;
}

.footer {
    display: flex;
    justify-content: center;
    color: rgb(120,120,120);
    font-size: 14px;

    
    margin-bottom: 55px;
}

.header_centered {
    justify-content: center;
    padding-bottom: 20px;
    text-align: center;
}

.command_help {
    display: flex;
    align-items: center;
}
.command_help :global(.material-icons) {
    font-size: 16px;
    margin-right: 6px;
    opacity: 0.8;
}

.return_email_command_container {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    width: 100%;
    
}
.return_email_command {
    border-radius: 6px;
    padding: 15px 10px;
    border: 1px solid rgb(120,120,120);;
    cursor: pointer;
    color: rgb(80,80,80);
}