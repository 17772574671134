.root {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
}

.grid-row {
    display: flex;
    align-items: center;
}
.grid-row input {
    width: 100%;
}