.root {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}
.stage {
    height: 7px;
    width: 7px;
    border-radius: 4px;
    border: 1px solid rgb(180,180,180);
    background-color: transparent;
}
.stage[data-complete='1'] {
    background-color: rgb(180,180,180);
}
.stage:not(:first-child) {
    margin-left: 10px;
}